import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ReferenceProvider {

  constructor() {
    console.log('Reference Provider');
  }

  getRoles() {
    let roles = [
      'Administrator',
      'Doctor',
      'Secretary'
    ]
    return roles;
  }
  getPolicies() {
    let policies = [
      'manage schedule',
      'manage records',
      'manage emr scan',
      'update billing',
      'manage doctor info',
      'manage clinic info',
      'delete records',
    ]
    return policies;
  }
}
